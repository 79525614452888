import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Beim Webdesign ist es wichtig, den verfügbaren Platz effizient zu nutzen. Ihr wollt den Bildschirm nicht mit
zu viel Inhalt überladen. Das ist der Grund für gängige Muster wie einblendbare Untermenüs, ausklappbare Bereiche,
Popups oder Dialoge.`}</p>
    <p>{`Häufig werden diese Elemente als benutzerdefinierte Widgets implementiert. Sie verwenden CSS-Code
wie `}<InlineCode mdxType="InlineCode">{`opacity: 0`}</InlineCode>{` oder `}<InlineCode mdxType="InlineCode">{`position: absolute; left: -10000px`}</InlineCode>{`, um den
Inhalt nur visuell zu verbergen. Die Nutzer:innen können aber immer noch über die Tastatur oder mit Hilfe von
assistiven Technologien auf den Inhalt zugreifen. Was können wir dagegen tun?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAGjDZJXjCX/xAAcEAADAAEFAAAAAAAAAAAAAAABAgMABBESExT/2gAIAQEAAQUCsUkkXFMMBl5p5tIB2ty3/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABoQAAIDAQEAAAAAAAAAAAAAAAABEjFhEBH/2gAIAQEABj8Cv1tmlEo7yz//xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAhoTFh/9oACAEBAAE/Ibj4QmlksdLktV5lKFgJ+4zhOXIced//2gAMAwEAAgADAAAAECTP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/ELiv/8QAFREBAQAAAAAAAAAAAAAAAAAAEDH/2gAIAQIBAT8Qg//EAB0QAQACAgIDAAAAAAAAAAAAAAERIQBRMdFBYZH/2gAIAQEAAT8QQhqDaNrqsMOyKkprcl/cRhPrs40AAFAUd4INCKHhx+Ux5Of/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ein Faultier hängt an einem Ast.",
          "title": "Ein Faultier hängt an einem Ast.",
          "src": "/static/30a9c9e15e8bc429a847e59efa54c12e/e5166/pexels-roxanne-minnish-sloth.jpg",
          "srcSet": ["/static/30a9c9e15e8bc429a847e59efa54c12e/f93b5/pexels-roxanne-minnish-sloth.jpg 300w", "/static/30a9c9e15e8bc429a847e59efa54c12e/b4294/pexels-roxanne-minnish-sloth.jpg 600w", "/static/30a9c9e15e8bc429a847e59efa54c12e/e5166/pexels-roxanne-minnish-sloth.jpg 1200w", "/static/30a9c9e15e8bc429a847e59efa54c12e/b17f8/pexels-roxanne-minnish-sloth.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Roxanne Minnish / pexels.com`}</em></p>
    <p>{`Ihr könnt das HTML-Attribut `}<InlineCode mdxType="InlineCode">{`inert`}</InlineCode>{` verwenden, um jegliche Interaktion mit versteckten Inhalten
zu verhindern. Eine Art digitales Faultier, sozusagen. Schauen wir uns das Attribut, das inzwischen von allen wichtigen
Browsern unterstützt wird, einmal genauer an.`}</p>
    <h2>{`Wie das `}<InlineCode mdxType="InlineCode">{`inert`}</InlineCode>{`-Attribut funktioniert`}</h2>
    <p>{`Die inert-Eigenschaft ist ein globales HTML-Attribut, das den Browser anweist, jegliche Benutzerinteraktion mit einem
bestimmten Inhaltsbereich zu verhindern. Die `}<a parentName="p" {...{
        "href": "https://html.spec.whatwg.org/#the-inert-attribute"
      }}>{`HTML-Spezifikation`}</a>{`
dazu lautet:`}</p>
    <blockquote lang="en">
    The <BoldText mdxType="BoldText">inert</BoldText> attribute is a boolean attribute that indicates, by its presence, that the element and
    all its flat tree descendants [...] are to be made inert by the user agent.
    </blockquote>
    <p>{`Das bedeutet: Alle Nutzereingabe-Events für das Element und seine Nachkommen werden ignoriert, einschließlich der
Fokus-Events und Events von assistiven Technologien.`}</p>
    <p>{`Ein Beispiel: Ihr platziert ein Registrierungs-Formular außerhalb des Bildschirms und lasst es erst in den sichtbaren
Bereich gleiten, wenn eine bestimmte Schaltfläche gedrückt wird. Durch Hinzufügen von `}<InlineCode mdxType="InlineCode">{`inert`}</InlineCode>{`
zum `}<InlineCode mdxType="InlineCode">{`<`}{`form`}{`>`}</InlineCode>{`-Element wird sichergestellt, dass Tastatur-Nutzer:innen nicht versehentlich mit
dem Formular interagieren, während es sich außerhalb des Bildschirms befindet. Der Inhalt wird auch vor Screenreadern verborgen.`}</p>
    <p>{`Auch das native `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{`-Element macht davon Gebrauch. Wenn ihr einen Modaldialog
mit `}<InlineCode mdxType="InlineCode">{`.showModal()`}</InlineCode>{` öffnet, wird der Browser automatisch alle anderen Inhalte inaktiv machen.
Mehr Infos liefert mein Blogartikel `}<a parentName="p" {...{
        "href": "/de/native-dialog-element.de/"
      }}>{`„Warum du das native Dialog-Element nutzen solltest“`}</a>{`.`}</p>
    <h2>{`Beispiel: Animiertes Navigations-Menü`}</h2>
    <p>{`Ich habe eine `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/fancy-css-playground/#/inert"
      }}>{`Demo`}</a>{` für ein animiertes Navigationsmenü
erstellt. Dort nutze ich das `}<InlineCode mdxType="InlineCode">{`inert`}</InlineCode>{`-Attribut für die versteckten Untermenüs. Das Ganze
funktioniert perfekt für Tastatur- und Screenreader-Nutzer:innen.`}</p>
    <iframe src="https://alexlehner86.github.io/fancy-css-playground/#/inert" title="Anwendungsbeispiel für das inert Attribut" loading="lazy"></iframe>
    <p>{`Das Navigationsmenü ist als ungeordnete Liste innerhalb eines nav-Elements aufgebaut. Der HTML-Code für einen Menüpunkt
und sein verborgenes Untermenü sieht wie folgt aus:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<button type="button" aria-expanded="false" aria-controls="sub_menu_research">
    Research
</button>
<div id="sub_menu_research" class="subMenu" inert>
    <ul>
        <li><a href="/black-holes">Black Holes</a></li>
        <li><a href="/climate-change">Climate Change</a></li>
        <li><a href="/modern-way-of-work">Modern Way of Work</a></li>
    </ul>
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Im CSS-Code referenziere ich auf das inert-Attribut, um das Untermenü auch visuell auszublenden:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.subMenu {
    overflow: hidden;
}

.subMenu[inert] > ul {
    transform: translateY(-100%);
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Wenn der Menüpunkt aktiviert wird, wird das `}<InlineCode mdxType="InlineCode">{`inert`}</InlineCode>{`-Attribut entfernt und
das Untermenü eingeblendet:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.subMenu:not([inert]) > ul {
    transform: translateY(0);
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Die Gleit-Animation könnt ihr ziemlich einfach und unkompliziert mit der CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`transition`}</InlineCode>{`
erzeugen:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.subMenu > ul {
    transition: transform 0.4s ease-in-out;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Hier findet ihr den `}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/fancy-css-playground/tree/main/src/pages/InertAttribute"
      }}>{`kompletten Quellcode`}</a>{`.`}</p>
    <h2>{`Warum nicht mit `}<InlineCode mdxType="InlineCode">{`display: none`}</InlineCode>{`?`}</h2>
    <p>{`Einige von euch denken jetzt vielleicht: `}<ItalicText mdxType="ItalicText">{`„Warum sollte ich das inert-Attribut verwenden? Ich kann doch
einfach alles mit display: none ausblenden!“`}</ItalicText>{` Ihr habt Recht. Wenn man die
CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`display: none`}</InlineCode>{` auf ein Element anwendet, wird es vom Browser nicht gerendert
und wird vor assistiven Technologien verborgen. Der Nachteil: Ihr könnt das Element nicht mehr animieren.`}</p>
    <p>{`Die Nutzung des `}<InlineCode mdxType="InlineCode">{`inert`}</InlineCode>{`-Attributs bietet hingegen mehr Flexibilität. Ihr könnt euren Inhalt ein-
und ausblenden, von der Seite reingleiten lassen oder jede erdenkliche anspruchsvolle Animation erstellen. Außerdem
könnt ihr das Attribut für Inhalte verwenden, die zwar sichtbar sind, mit denen aber nicht interagiert werden soll.
Zum Beispiel die teilweise sichtbare, vorherige bzw. nächste Folie in einem Karussell-Widget.`}</p>
    <h2>{`Browser-Unterstützung`}</h2>
    <p>{`Seit Version 112 unterstützt nun auch Firefox endlich das `}<InlineCode mdxType="InlineCode">{`inert`}</InlineCode>{`-Attribut. Das bedeutet,
dass nun `}<a parentName="p" {...{
        "href": "https://caniuse.com/mdn-api_htmlelement_inert"
      }}>{`alle modernen Browser`}</a>{` dieses Feature unterstützen.`}</p>
    <p>{`Na dann: Los geht's! Lasst uns coole Sachen damit bauen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      